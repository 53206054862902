define("ember-style-modifier/modifiers/style", ["exports", "ember-modifier", "@ember/string", "@ember/debug", "@ember/utils"], function (_exports, _emberModifier, _string, _debug, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function isObject(o) {
    return typeof o === 'object' && Boolean(o);
  }
  class StyleModifier extends _emberModifier.default {
    /**
     * Returns a two-dimensional array, like:
     *
     * ```js
     * [
     *   ['font-size', '16px'],
     *   ['text-align', 'center'],
     *   ['color', 'red']
     * ]
     * ```
     *
     * This data structure is slightly faster to process than an object / dictionary.
     */
    get styles() {
      const {
        positional,
        named
      } = this.args;

      // This is a workaround for the missing `Array#flat` in IE11.
      return [].concat(...[...positional.filter(isObject), named].map(obj => Object.entries(obj).map(_ref => {
        let [k, v] = _ref;
        return [(0, _string.dasherize)(k), v];
      })));
    }
    setStyles(newStyles) {
      const rulesToRemove = this._oldStyles || new Set();
      newStyles.forEach(_ref2 => {
        let [property, value] = _ref2;
        (true && !(typeof value === 'undefined' || (0, _utils.typeOf)(value) === 'string') && (0, _debug.assert)(`Your given value for property '${property}' is ${value} (${(0, _utils.typeOf)(value)}). ` + 'Accepted types are string and undefined. Please change accordingly.', typeof value === 'undefined' || (0, _utils.typeOf)(value) === 'string')); // priority must be specified as separate argument
        // value must not contain "!important"
        let priority = '';
        if (value && value.includes('!important')) {
          priority = 'important';
          value = value.replace('!important', '');
        }

        // support camelCase property name
        property = (0, _string.dasherize)(property);

        // update CSSOM
        this.element.style.setProperty(property, value, priority);

        // should not remove rules that have been updated in this cycle
        rulesToRemove.delete(property);
      });

      // remove rules that were present in last cycle but aren't present in this one
      rulesToRemove.forEach(rule => this.element.style.removeProperty(rule));

      // cache styles that in this rendering cycle for the next one
      this._oldStyles = new Set(newStyles.map(e => e[0]));
    }
    didReceiveArguments() {
      this.setStyles(this.styles);
    }
  }
  _exports.default = StyleModifier;
});